<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-md-12">
                <g-loading v-if="firstLoading" />
                <div v-else class="card title">
                    <div class='card-header c-leader-head text-center'>
                        <h3 class='card-title' style="float: none">Báo cáo chi tiết hàng quý</h3>
                    </div>
                    <div class="card-body title-filter">
                        <div class="row">
                            <div class="col-4"></div>
                            <div class="col-8 title-filte-right">
                                <b-tabs class="c-tabs-button mr-2" small pills>
                                    <b-tab v-for="year in years" :key="year"  :title="`Năm ${year}`" :active="filterFormModel.year == year" @click='setTab(year)'></b-tab>
                                </b-tabs>

                                <g-button class="ml-2" v-if="isPrime() && permissions('report_create')" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Gửi báo cáo" @click='create' />
                                <button  class="btn btn-gray btn-sm mr-1 ml-1" @click="load">
                                    <b-spinner v-if="listLoading" small></b-spinner>
                                    <i v-else class="fa fa-sync-alt" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body title-body pt-0">
                        <div class="title-template-table  table-responsive">
                            <table class='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr>
                                        <th class='text-center'>Đơn vị</th>
                                        <th class='text-center'>Quý 1</th>
                                        <th class='text-center'>Quý 2</th>
                                        <th class='text-center'>Quý 3</th>
                                        <th class='text-center'>Quý 4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="parent in parents" :key="parent.id" >
                                        <td>{{parent.name}}</td>
                                        <td class='text-center'>
                                            <div v-if="getItem(parent.id, 1)" >
                                                <i style="font-size: 20px;" class="fa fa-file-pdf"></i>
                                                <div><span class="small text-gray-999">Ngày báo cáo: {{getItem(parent.id, 1).updated_time * 1000 |  moment('DD/MM/YYYY')}}</span></div>
                                                <div>{{getItem(parent.id, 1).title}}</div>
                                                <div v-if="getItem(parent.id, 1).blocked" class="text-danger">Bản lưu trữ</div>
                                                <div>
                                                    <a class="btn btn-sm btn-outline-gray" v-if="medias[getItem(parent.id, 1).file_id]" :href="medias[getItem(parent.id, 1).file_id].url" :title="medias[getItem(parent.id, 1).file_id].name" target="_blank"><i class="fa fa-eye"></i></a>
                                                    <g-button v-if="permissions('report_update') && isPrime()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1" icon="pencil"   @click="update(getItem(parent.id, 1).id)"/>
                                                </div>
                                            </div>
                                            <div v-else >
                                                <span class="text-danger small text-gray-999" ></span>
                                            </div>
                                        </td> 
                                        <td class='text-center'>
                                            <div v-if="getItem(parent.id, 2)" >
                                                <i style="font-size: 20px;" class="fa fa-file-pdf"></i>
                                                <div><span class="small text-gray-999">Ngày báo cáo: {{getItem(parent.id, 2).updated_time * 1000 |  moment('DD/MM/YYYY')}}</span></div>
                                                <div>{{getItem(parent.id, 2).title}}</div>
                                                <div v-if="getItem(parent.id, 2).blocked" class="text-danger">Bản lưu trữ</div>
                                                <div>
                                                    <a class="btn btn-sm btn-outline-gray" v-if="medias[getItem(parent.id, 2).file_id]" :href="medias[getItem(parent.id, 2).file_id].url" :title="medias[getItem(parent.id, 2).file_id].name" target="_blank"><i class="fa fa-eye"></i></a>
                                                    <g-button v-if="permissions('report_update') && isPrime()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1" icon="pencil"   @click="update(getItem(parent.id, 2).id)"/>
                                                </div>
                                            </div>
                                            <div v-else >
                                                <span class="text-danger small text-gray-999" ></span>
                                            </div>
                                        </td> 
                                        <td class='text-center'>
                                            <div v-if="getItem(parent.id, 3)" >
                                                <i style="font-size: 20px;" class="fa fa-file-pdf"></i>
                                                <div><span class="small text-gray-999">Ngày báo cáo: {{getItem(parent.id, 3).updated_time * 1000 |  moment('DD/MM/YYYY')}}</span></div>
                                                <div>{{getItem(parent.id, 3).title}}</div>
                                                <div v-if="getItem(parent.id, 3).blocked" class="text-danger">Bản lưu trữ</div>
                                                <div>
                                                    <a class="btn btn-sm btn-outline-gray" v-if="medias[getItem(parent.id, 3).file_id]" :href="medias[getItem(parent.id, 3).file_id].url" :title="medias[getItem(parent.id, 3).file_id].name" target="_blank"><i class="fa fa-eye"></i></a>
                                                    <g-button v-if="permissions('report_update') && isPrime()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1" icon="pencil"   @click="update(getItem(parent.id, 3).id)"/>
                                                </div>
                                            </div>
                                            <div v-else >
                                                <span class="text-danger small text-gray-999" ></span>
                                            </div>
                                        </td>
                                        <td class='text-center'>
                                            <div v-if="getItem(parent.id, 4)" >
                                                <i style="font-size: 20px;" class="fa fa-file-pdf"></i>
                                                <div><span class="small text-gray-999">Ngày báo cáo: {{getItem(parent.id, 4).updated_time * 1000 |  moment('DD/MM/YYYY')}}</span></div>
                                                <div>{{getItem(parent.id, 4).title}}</div>
                                                <div v-if="getItem(parent.id, 4).blocked" class="text-danger">Bản lưu trữ</div>
                                                <div>
                                                    <a class="btn btn-sm btn-outline-gray" v-if="medias[getItem(parent.id, 4).file_id]" :href="medias[getItem(parent.id, 4).file_id].url" :title="medias[getItem(parent.id, 4).file_id].name" target="_blank"><i class="fa fa-eye"></i></a>
                                                    <g-button v-if="permissions('report_update') && isPrime()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1" icon="pencil"   @click="update(getItem(parent.id, 4).id)"/>
                                                </div>
                                            </div>
                                            <div v-else >
                                                <span class="text-danger small text-gray-999" ></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row' >
                <div class='col-md'>
                    <form-input :required='true' :disabled='formModel.id' type='number' label="Năm" :model="formModel" attribute="year" :errors="formErrors"/>
                </div>
                <div class='col-md'>
                    <form-select :required='true' :disabled='formModel.id' label="Quý" :model="formModel" attribute="quarter" :options='quarterOptions' :errors="formErrors"/>
                </div>
            </div>
            <form-input :required='true' label="Tiêu đề" :model="formModel" attribute="title" :errors="formErrors"/>
            <form-textarea label="Mô tả" :required='false' :model="formModel" :errors="formErrors" attribute="desc" />
            <form-media  :required='true'  :model="formModel" label="Báo cáo đính kèm" attribute="file_id" placeholder="Chọn tài liệu định dạng .pdf" :errors="formErrors" accept="application/pdf" />
            <form-checkbox chk-label="Bản nháp, lưu trữ tại đơn vị" :model="formModel" :errors="formErrors"  attribute="blocked" />

            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </main>
</template>


<script>
    import moment from 'moment';
    import role from '@/mixins/Role';
    import crud from '@/mixins/CRUD';
    import FormMedia from '@/components/media/FormMedia';

    export default {
        mixins: [role, crud],
        components: {FormMedia},
        data: function () {
            return {
                service: '/report',
                firstLoading: true,
                listLoading: false,
                filterFormModel: {
                    year: moment().format("YYYY"),
                },
                defaultFormModel: {
                    quarter: moment().quarter(),
                    year: moment().format("YYYY"),
                    blocked: 0,
                    file_id: null
                },
                quarterOptions: [
                    {value: null, text: "Chọn quý"},
                    {value: 1, text: "Quý 1"},
                    {value: 2, text: "Quý 2"},
                    {value: 3, text: "Quý 3"},
                    {value: 4, text: "Quý 4"}
                ],
                items: [],
                medias: [],
                years: [],
                parents: []
            };
        },
        methods: {
            getItem(client, quarter) {
                return this.items[this.filterFormModel.year] && this.items[this.filterFormModel.year][client] && this.items[this.filterFormModel.year][client][quarter] ? this.items[this.filterFormModel.year][client][quarter] : null;
            },
            setTab(tab) {
                this.filterFormModel.year = tab;
                this.load();
            },
            load() {
                this.listLoading = true;
                this.$service.get(`${this.service}/index`, {params: this.filterFormModel}).then(response => {
                    if (response.data) {
                        this.items = response.data.items;
                        this.medias = response.data.medias;
                        this.years = response.data.years;
                        this.parents = response.data.parents;
                    }
                }).finally(() => {
                    this.firstLoading = false;
                    this.listLoading = false;
                });
            },
        },
        mounted: function () {
            this.load();
        },
        beforeDestroy: function () {
        },
    }
</script>